.terms-page {
	.body-wrap {
		.start-page {
			text-align: center;
			margin: 30px 0;
			border-style: solid;
			border-color: #737373;
			border-width: 2px 0;
			border-width: 1.5px 0;

			p {
				margin: 25px 0;
			}
		}

		.tnc-wrap {
			list-style: none;
			display: flex;
			align-items: self-start;
			justify-content: flex-start;
			padding-bottom: 15px;

			.srno {
				width: 5%;
			}

		 	.content {
				width: 95%;
			}


		}

		.txtCt {
			text-align: center;
		}

		.exhibitA {
			display: flex;
			padding: 10px 0;

			.col1 {
				width: 4%;
			}

			.col2 {
				width: 25%;
			}

			.exhibitA .col3 {
				width: 71%;
			}
		}

		.exhibitB {
			display: flex;
			padding: 5px 0;

			.col1 {
				width: 4%;
			}

			.col2 {
				width: 96%;
			}
		}

		p.grievance {
			font-size: 16px;
			line-height: 22px;
			padding: 5px 0;
			color: #333333;
		}
	}
}