body {
	font-family: 'Roboto';
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

.container {
	padding: 0 100px;

	.header-wrap {
		border-bottom: solid 3px #24B24B;
		display: grid;
    align-items: center;
    margin-bottom: 20px;
    grid-template-columns: 1fr 10fr;
    grid-column-gap: 10px;

		.header-wrap-left {
			width: 80px;
			height: 74px;
			grid-row: span 2;
		}

		.header-warp-right {
			padding: 0 10px;

			
		}
		.company-name {
			color: #24B24B;
			font-size: 25px;
			font-weight: 600;
			padding-bottom: 2px;
		}

		.company-address {
			color: #404040;
			font-size: 16px;
			padding-top: 2px;
		}
	}
}

.heading1 {
	font-size: 32px;
	color: #000000;
}

.heading2 {
	font-size: 25px;
	color: #000000;
	padding: 10px 0;
}

.footer-wrap {
	height: 100px;
}

.footer-line {
	border-bottom: solid 3px #24B24B;
}

.footer-info {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	color: #404040;

	.footer-info-center {
		border-left: solid 2px #A9A9A9;
		height: 25px;
		margin: 0 20px;
	}
}

@media only screen and (max-width: 660px) {
	.container {
		padding: 0 25px;
		.header-wrap{
			border: 0;
			.company-address{
				grid-column: span 2;
			}
			.header-wrap-left{
				grid-row: span 1;
			}
		}
	}
}