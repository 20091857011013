.privacy-page {
	.body-wrap {
		p {
			font-size: 16px;
			line-height: 22px;
			padding: 5px 0;
			color: #333333;
		}
		ul {
			padding-left: 45px;
	
			li {
				line-height: 25px;
			}
		}
	}
}