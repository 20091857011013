.login-page {
	display: flex;
	width: 100%;
	padding: 0;
	height: 100vh;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;

	.left--part {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin-top: 90px;
		width: 60%;

		.login--wrapper {
			background: #fff;
			border: 1px solid #777;
			border-radius: 20px;
			box-shadow: 0 1px 1px rgba(0, 0, 0, .1);
			margin-bottom: 20px;
			padding: 25px 35px;
			width: 440px;

			.login--logo--wrapper {
				text-align: center;
			}

			.head--txt {
				color: #104fa2;
				font-size: 24px;
				font-style: italic;
				font-weight: 500;
				padding-bottom: 30px;
				text-align: center;
			}

			.nav--tab {
				display: flex;

				div {
					border-bottom: 1px solid #777;
					color: #222;
					cursor: pointer;
					font-size: 16px;
					padding-bottom: 10px;
					text-align: center;
					width: 50%;

					&.active {
						border-bottom: 4px solid #104fa2;
						color: #104fa2;
						font-weight: 600;
					}
				}
			}

			.login--content,
			.password--content {
				margin-top: 30px;
			}
		}

		.copy--right--txt {
			color: #000;
			font-size: 16px;
			font-weight: 500;
		}
	}

	.right--part {
		background: #e5f6fd;
		height: 100%;
		width: 40%;

		.helpline--wrapper {
			padding: 30px;

			.head--txt--wrap div {

				&:first-child {
					color: #000;
					font-size: 20px;
					font-weight: 700;
				}

				&:last-child {
					border-bottom: 1px solid #4579b8;
					color: #4579b8;
					font-size: 15px;
					font-weight: 500;
					padding: 10px 0;
				}
			}
			 .helpline--wrap {
				padding-bottom: 10px;
				div{
					&:first-child {
						color: #000;
						font-size: 16px;
						font-weight: 700;
				}
				&:last-child {
					color: #000;
					font-size: 14px;
					font-weight: 500;
					padding: 5px 0;
			}
				}
		}
		}
	}

	.input--wrapper {
		margin-bottom: 15px;

		.input--content {
			position: relative;
			width: 100%;

			input {
				background: #fff;
				border: 1px solid #777;
				border-radius: 4px;
				color: #000;
				font-size: 15px;
				font-weight: 500;
				outline: none;
				overflow: hidden;
				padding: 8px 13px;
				text-overflow: ellipsis;
				white-space: nowrap;
				width: 100%;


			}

			.input--label {
				color: #222;
				font-size: 15px;
				font-weight: 500;
				left: 10px;
				pointer-events: none;
				position: absolute;
				top: 6px;
				transition: .3s ease;

				&.active {
					top: -8px;
					left: 10px;
					padding: 0 3px;
					font-size: 12px;
					background: #fff;
					color: #104FA2;
					font-weight: 500;
				}

				&.filled {
					top: -8px;
					left: 10px;
					padding: 0 3px;
					color: #000;
					background: #fff;
					font-size: 12px;
					font-weight: 500;
				}
			}

			.icon {
				position: absolute;
				top: 8px;
				right: 10px;
				cursor: pointer;
			}

			.icon--drop {
				position: absolute;
				top: 6px;
				right: 8px;
				cursor: pointer;
			}

			.field-error {
				color: red;
				font-size: 12px;
			}
		}
	}

	.submit--btn {
		align-items: center;
		background: #ff9c08;
		border-radius: 5px;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .2);
		color: #fff;
		cursor: pointer;
		display: flex;
		font-size: 16px;
		font-weight: 600;
		justify-content: center;
		padding: 10px 0;
	}
}